:root {
    --margin: 24px;
}

.tBoxAPD {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
    color: #1A1A1A;
}

.descBoxAPD {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #1A1A1A;
}

.h3APD {
    /* Heading 3 */
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000C7F;
    margin: 4px;
}

p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #595959;
}

.DoughnutWrapper {
    margin: 4px var(--margin) 16px var(--margin);
    position: relative;
    display: flow-root;
}

.DoughnutWrapper .chart .centerContent {
    position: absolute;
    padding-top: 36px;
    width: 120px;
    height: 120px;
    text-align: center;
}

.DoughnutWrapper .chart {
    display: block;
    width: 120px;
    height: 120px;
    float: left;
    position: relative;
}

.legendLine {
    height: 24px;
    margin-bottom: 4px;
}

.legendLine .miniCircle,
.legendLine .legendTag,
.legendLine .counter {
    float: left;
    margin-right: 8px;
}

.legendLine .counter {
    margin-right: 0px;
}

.legendLine .legendTag {
    margin-top: 4px;
    width: 95px;
}

.legendLine .miniCircle {
    margin-top: 6px;
    margin-left: 12px;
    width: 12px;
    height: 12px;
    background: cornsilk;
    display: block;
    border-radius: 24px;
}

.checkbox_line {
    height: 24px;
}

.checkbox_line label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #425866;
    margin: 8px 0px;
}

.map-screen .title-p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #595959;
}

.InfoTagValue .tag {
    font-size: 14px;
    line-height: 24px;
    color: #2E414D;
}

.InfoTagValue .value {
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    color: #2E414D;
    /* text-transform: uppercase; */
}

.DoughnutWrapper .legend {
    display: block;
    float: left;
}

.ShowKpiInfo {
    padding-top: 12px;
    padding-bottom: 12px;
    /* background-color: red; */
}

.ShowKpiInfo .tag {
    font-size: 18px;
    color: #5A6D79;
    font-weight: 400;
    line-height: 30px;
}

.ShowKpiInfo .value {
    font-size: 32px;
    line-height: 40px;
    font-weight: 800;
}