body {
  background-color: #f2f2f2;
}

.analytics-page {
  padding-top: 24px;
  padding-bottom: 24px;
}

.menu-container {
  margin-left: 12px;
}

.analytics-page .box-wrapper {
  margin-left: 24px;
  margin-top: 24px;
  margin-right: 24px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  height: 400px;
}

.analytics-page .title-icon {
  float: left;
  margin-right: 8px;
  margin-top: 5px;
}

.analytics-page .columnsMid {
  padding-top: 20px;
  columns: 2 400px;
  margin: 0 24px;
  column-gap: 24px;
}

.analytics-page .columnsKpi {
  padding-top: 0;
  margin-top: 24px;
  columns: 2 50px;
  column-gap: 48px;
  column-rule: 1px solid #3f464e;
}

.analytics-page .columnsMid .box-wrapper-left,
.analytics-page .columnsMid .box-wrapper-right {
  margin-bottom: 24px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
}

.analytics-page .page-header {
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  justify-content: space-between;
}

.analytics-page .alighL {
  display: flex;
  justify-content: flex-start;
}

.analytics-page .alighR {
  margin-top: -18px;
  display: flex;
  justify-content: flex-end;
}
.analytics-page .alighR > div + div {
  margin-left: 16px;
}

@media screen and (max-width: 720px) {
  .analytics-page .page-header {
    display: block;
  }
  .analytics-page .alighR {
    display: block;
  }
  .analytics-page .alighR > div {
    margin-top: 1rem;
  }
  .analytics-page .alighR > div + div {
    margin-left: 0;
  }
}
