
.margin {
    height: 50px;
}

.contentWrapper {
    margin-top: 50px;
    height: calc(100vh - 50px);
}

.body_1 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #1A1A1A;
    margin: 0;
}

.body_2 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #1A1A1A;
    margin: 0;
}

.body_1_Highlight {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #1A1A1A;
    margin: 0;
}