.page-alert .MuiTableRow-head .MuiTableCell-head {
    background-color: #F2F2F2;
    font-weight: bolder !important;
}

body {
    background-color: #F2F2F2;
}

.page-alert .page-header {
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    justify-content: space-between;
}

.page-alert .alighL {
    display: flex;
    justify-content: flex-start;
}

.page-alert .alighR {
    margin-top: -18px;
    display: flex;
    justify-content: flex-end;
}
.page-alert .alighR>div + div {
    margin-left: 16px;
}

.page-alert {
    padding-top: 20px;
}

.page-alert .box-wrapper {
    margin: 24px;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;
}

.page-alert .box-wrapper .table-wrapper {
    margin-top: 24px;
}

.page-alert .title-icon {
    float: left;
    margin-right: 8px;
    margin-top: 5px;
}

.page-alert .box-wrapper .table-wrapper .cellMainValue {
    min-width: 150px;
}

.page-alert .box-wrapper .table-wrapper .cellSecValue {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.57);
}

.page-alert .box-wrapper .table-wrapper .numIndexSize {
    max-width: 30px;
}

.page-alert .box-wrapper .table-wrapper .providerSizeName {
    max-width: 50px;
}

.page-alert .box-wrapper .table-wrapper .vehicleIdSizeName {
    max-width: 190px;
}
.page-alert .box-wrapper .table-wrapper .date {
    max-width: 90px;
}

@media screen and (max-width: 720px) {
    .page-alert .page-header {
      display: block;
    }
    .page-alert .alighR {
      display: block;
    }
    .page-alert .alighR > div {
      margin-top: 1rem;
    }
    .page-alert .alighR > div + div {
      margin-left: 0;
    }
  }